import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FdlDialogConfig, ConfirmationModalConfig } from '@fyle/model-shared';
import { ConfirmationModalComponent } from '@fyle/confirmation-modal';
import { IndeterminateLoaderComponent } from '@fyle/indeterminate-loader';
import { AnimationEvent } from '@angular/animations';
@Injectable({
  providedIn: 'root',
})
export class FdlDialogService {
  defaultDialogConfiguration: FdlDialogConfig = {
    showHeader: false,
    contentStyle: { padding: 0 },
  };

  constructor(private dialogService: DialogService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open(dynamicComponent: Type<any>, dialogConfig?: FdlDialogConfig): DynamicDialogRef {
    const ref = this.dialogService.open(dynamicComponent, {
      ...this.defaultDialogConfiguration,
      ...dialogConfig,
    });

    return ref;
  }

  openConfirmationModal(modalConfig: ConfirmationModalConfig, focusOnClose?: boolean): DynamicDialogRef {
    return this.open(ConfirmationModalComponent, { data: modalConfig, focusOnClose: focusOnClose });
  }

  openIndeterminateLoaderModal(loaderText: string): DynamicDialogRef {
    return this.open(IndeterminateLoaderComponent, { data: loaderText });
  }
}
