export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: 'undefined',
    ROOT_URL: 'https://us1.fylehq.com',
    APP_URL: 'https://app1.fylehq.com/app',
    APP_ROUTER_URL: 'https://accounts.fylehq.com',
    ROUTER_URL: 'https://accounts.fylehq.com',
    SENTRY_DSN: 'https://4a085c2fd6d64406821ecc300cc7e42b@o341960.ingest.sentry.io/3554020',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.1,
    ENVIRONMENT: 'prod',
    RELEASE: '5bfe23c22e635aac067666792b6afd3db694e340',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDf-vdrlLzxZsgGqWUd_yiOXCJ6ToB4l9w',
    DWOLLA_ENVIRONMENT: 'prod',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe55',
    NETLIFY_FYLE_APP_KEY: 'NETLIFY_FYLE_APP_KEY',
    NETLIFY_FUNCTION_URL: 'undefined',
    DEPLOY_LOGS_WEBHOOK_URL: 'undefined',
    C1_DEPLOY_LOGS_WEBHOOK_URL: 'undefined',
    SLACK_COPILOT_FEEDBACK_WEBHOOK: 'undefined',
    NETLIFY_ACCESS_TOKEN: 'undefined',
    MIXPANEL_PROJECT_TOKEN: '56c5f4864cd63c40ee61dc476457f693',
    MIXPANEL_PROXY_URL: 'https://us1.fylehq.com/mixpanel',
    USE_MIXPANEL_PROXY: 'true',
    ENABLE_MIXPANEL: 'true',
    YODLEE_FAST_LINK_URL: 'undefined',
  };