@if (!validateForm) {
  <p-button
    [type]="defaultType"
    styleClass="fdl-button button-{{buttonType}} button-{{buttonSize}} {{customClasses}}"
    [label]="isLoading ? (loadingText || label) : label"
    [loading]="isLoading"
    [disabled]="isDisabled"
    [fdlTooltip]="tooltip"
    [iconPos]="isLoading ? 'right' : iconPos"
    [tooltipDirection]="tooltipPosition"
    (click)="onClick($event)"
    [badge]="badge"
    [badgeClass]="badgeClasses"
    >
    @if (customPrefixTemplate) {
      <div>
        <ng-container *ngTemplateOutlet="customPrefixTemplate"></ng-container>
      </div>
    }
    
    @if (icon) {
      <!-- To make the iconClasses work, we have to pass the class variable explicitly in the template, this behaviour was changed in this PR from primeng: primefaces/primeng#15582 -->
      <ng-template pTemplate="icon" let-class="class">
        <span [class]="class">
          <ui-svg-sprite
          [icon]="icon"
          [containerClasses]="iconContainerClasses"
          [classes]="iconClasses">
          </ui-svg-sprite>
        </span>
      </ng-template>
    }

    <ng-template pTemplate="loadingicon" let-class="class">
      <span [class]="class">
        <ui-svg-sprite
          [icon]="'loader'"
          [classes]="'tw-h-12-px tw-w-12-px tw-fill-white tw-animate-spinner'"
          [containerClasses]="'tw-flex tw-ml-spacing-4'">
        </ui-svg-sprite>
      </span>
    </ng-template>

  </p-button>
}

@if (validateForm) {
  <p-button
    [type]="defaultType"
    styleClass="fdl-button button-{{buttonType}} button-{{buttonSize}} {{customClasses}}"
    [label]="label"
    [isLoading]="isLoading"
    [disabled]="isDisabled"
    [iconPos]="iconPos"
    (click)="onClick($event)"
    [fdlTooltip]="tooltip"
    [tooltipDirection]="tooltipPosition"
    [loadingText]="loadingText"
    [formToValidate]="formToValidate"
    [formElement]="formElement"
    fylAppFormValidationButtonLoader
    >
    @if (icon) {
      <ng-template pTemplate="icon" let-class="class">
        <span [class]="class">
          <ui-svg-sprite
          [icon]="icon"
          [containerClasses]="iconContainerClasses"
          [classes]="iconClasses">
          </ui-svg-sprite>
        </span>
      </ng-template>
    }

  </p-button>
}